'use strict';

(function () {
  const ENTER_KEY = 'Enter';
  const ESCAPE_KEY = 'Escape';
  const SPACE_KEY = ' ';
  const FOCUSED_ELEMENT = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex="0"]';

  /**
   * Создание DIV элемнента с Одним классом
   * @param  {String} className — название класса
   * @return {HTMLElement}
   */
  function createDivWithClass(className) {
    let div = document.createElement('div');
    div.classList.add(className);

    return div;
  }

  /**
   * Высчитывание ширина скроллбара
   * @return {Number}
   */
  function getScrollbarWidth() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      return window.innerWidth - document.documentElement.clientWidth;
    }
    return 0;
  }

  /**
   * Проставить детям transition-delay по порядку
   * @param  {Object} parentElement — DOM элемент родителя
   * @return {undefined}
   */
  function setTransitionForChildren(parentElement) {
    let countChildren = parentElement.querySelectorAll('.js-transition-child'),
      transitionDelay = +parentElement.dataset.delay || 100,
      transitionStart = +parentElement.dataset.start || 0;
    countChildren.forEach(function (current) {
      current.style.transitionDelay = transitionStart + 'ms';
      transitionStart = transitionStart + transitionDelay;
    })
  }

  /**
   * Возвращает строку в виде числа с нулём впереди если число меньше 10
   * @param  {Number} number — число
   * @return {String}
   */
  function setZeroFirst(number) {
    return (number < 10) ? '0' + number : '' + number;
  }

  /**
   * Возвращает полноценный массив из псевдомасива
   * @param  {HTMLCollection} pseudoArray — псевдо массив
   * @return {array}
   */
  function getArrayFrom(pseudoArray) {
    return Array.from(pseudoArray);
  }

  /**
   * Плаваная прокурутка страницы до заданного элемента
   *
   * Примеры:
   *
   * ```js
   * window.scrollPageTo('#some-section', 2000);
   * window.scrollPageTo(document.getElementById('some-section'), 1000);
   * window.scrollPageTo(500); // will scroll to 500px in 500ms
   * ```
   *
   * @param {HTMLElement | Number} to — Элемент куда будет совершенна прокрутка.
   *                                    Также можно задать число на которое будет совершена прокрутка страницы
   * @param {Number} duration [default = 500] — Длительность прокрутки
   * @returns {Promise}
   *
   * Оригинальная задумка @andjosh's
   *
   */
  function scrollPageTo(to, duration = 500) {
    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    const easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    return new Promise((resolve, reject) => {
      const element = document.scrollingElement;

      if (typeof to === 'string') {
        to = document.querySelector(to) || reject();
      }
      if (typeof to !== 'number') {
        to = to.getBoundingClientRect().top + element.scrollTop;
      }

      let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      const animateScroll = function () {
        currentTime += increment;
        element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        } else {
          resolve();
        }
      };
      animateScroll();
    });
  }

  /**
   * Суммирует числа в перечисленных input'ах и возвращает результат
   * @param  {HTMLCollection} input — Перечисление input'ов
   * @return {Number}
   */
  function collectNumberFromInputs(input) {
    let
      inputs = getArrayFrom(input),
      result = 0;

    inputs.forEach(function (current) {
      result += +current.value
    });

    return result;
  }

  window.utils = {
    createDivWithClass: createDivWithClass,
    getScrollbarWidth: getScrollbarWidth,
    setTransitionForChildren: setTransitionForChildren,
    setZeroFirst: setZeroFirst,
    getArrayFrom: getArrayFrom,
    scrollPageTo: scrollPageTo,
    collectNumberFromInputs: collectNumberFromInputs,
    keyEscape: ESCAPE_KEY,
    keyEnter: ENTER_KEY,
    keySpace: SPACE_KEY,
    focusedElement: FOCUSED_ELEMENT,
  }

})();
